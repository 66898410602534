import React, { useState } from "react";
import { Link } from "gatsby";

export default function Features({ heading, info, page, toggleclose }) {
  if (heading === "MedAnswers") {
    return (
      <div className="fetures">
        <a
          href={page}
          target="_blank"
          onClick={toggleclose}
          className="stretched-link"
        >
          <h4>{heading} </h4>
        </a>

        <p>{info}</p>
      </div>
    );
  }
  return (
    <div className="fetures">
      <Link to={page} onClick={toggleclose} className="stretched-link">
        <h4>{heading} </h4>
      </Link>

      <p>{info}</p>
    </div>
  );
}
