import React from "react";
import footerlogo from "../assets/images/footerlogo.png";
import instagram from "../assets/images/instagram.png";
import facebook from "../assets/images/facebook.png";
import twitter from "../assets/images/twitter.png";
import linkedin from "../assets/images/linkedin.png";
import youtube from "../assets/images/youtube.png";
import pintrest from "../assets/images/pintrest.png";
import { Link } from "gatsby";
export default function MenuItems() {
  return (
    <div className="logosection">
      <div className="mainlogo">
        <Link to="/">
          {" "}
          <img src={footerlogo} alt="Fertitlity Answers Logo" />
        </Link>
      </div>
      <div className="sociallinks">
        <a target="_blank" href="https://www.instagram.com/fertilityanswers_/">
          <img src={instagram} alt="Instagram Logo - Link to Instagram Profile" />
        </a>
        <a target="_blank" href="https://www.facebook.com/medanswers.fertility">
          {" "}
          <img src={facebook} alt="Facebook Logo - Link to Facebook Page" />
        </a>
        <a target="_blank" href="https://twitter.com/Medanswers_IVF">
          {" "}
          <img src={twitter} alt="Twitter Logo - Link to Twitter Profile" />
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/medanswers/">
          <img src={linkedin} alt="LinkedIn Logo - Link to LinkedIn Profile" />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCYH7kzYmFiMAWJOar2ORj9g"
        >
          <img src={youtube} alt="Youtibe Logo - Link to Youtube Channel" />
        </a>
        <a target="_blank" href="">
          <img src={pintrest} alt="Pinterest Logo - Link to Pinterest Profile" />
        </a>
      </div>
    </div>
  );
}
