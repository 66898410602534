import React from "react";
import { Link } from "gatsby";
import applestore from "../assets/images/applestore.png";
import playstore from "../assets/images/playstore.png";
export default function MediaApp() {
  return (
    <div className="mediaApp">
      <p>Get the App</p>
      <a
        target="_blank"
        href="https://apps.apple.com/us/app/fertilityanswers/id1418650176"
      >
        <img src={applestore} alt="Apple Store Logo - Link to App on Apple Store" />
      </a>
      <a
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.fertilityanswers"
      >
        <img src={playstore} alt="Play Store Logo - Link to App on Play Store" />
      </a>
    </div>
  );
}
