//all imports
import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import HamburgerMenu from "react-hamburger-menu";
import "./assets/css/style.scss";
import { Navbar, Nav, Form, Dropdown } from "react-bootstrap";
import logo from "./assets/images/mainlogo.png";
import Search from "./search/search.js";
import Choose from "../chooseRole/index.js";
import { toggleMenu } from "../../../state/app.js";
import { useDispatch, useSelector } from "react-redux";
import avatar from "../assets/images/avatar.svg";
import { removeTokenFromLocalStorage } from "../../../api/utils/local-storage";
import { SocialBox } from "../menu/menu.js";
import flag from "./assets/images/flag.png";

//main function
export default function Header({ page, auth }) {
  const showsocial = useRef();
  const [togglehamber, settogglehamber] = useState();
  const [choosebox, setchoosebox] = useState(false);
  const menustatus = useSelector((state) => state.app.openHambergermenu);
  const dispatch = useDispatch();
  useEffect(() => {
    settogglehamber(menustatus);
  }, [menustatus]);

  try {
    typeof window !== "undefined" &&
      useEffect(() => {
        window.addEventListener("scroll", function () {
          console.log(window.pageYOffset);
          window.pageYOffset > 400
            ? showsocial.current &&
            showsocial.current.classList.add("scrollactive")
            : showsocial.current &&
            showsocial.current.classList.remove("scrollactive");
        });
      }, []);
  } catch (e) { }

  var togglemenu = () => {
    document.body.classList.toggle("menuexpand");

    if (togglehamber) {
      dispatch(toggleMenu(false));
    } else {
      dispatch(toggleMenu(true));
    }
  };

  //jsx
  return (
    <>
      <div className="mainheader">
        <Navbar expand="lg">
          <Navbar.Brand>
            <Link to="/">
              {" "}
              <img src={logo} alt="Fertitlity Answers Logo" className="img-responsive" />
            </Link>
          </Navbar.Brand>
          {auth && (
            <Dropdown className="mobilesearchUser d-md-none">
              <Dropdown.Toggle className="user_logs" id="dropdown-basic">
                <img src={avatar} alt="logged-in user avatar" className="avatar-login" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link to="/account" style={{ display: "block" }}>
                  Account
                </Link>
                <Dropdown.Item>
                  <div onClick={() => removeTokenFromLocalStorage()}>
                    Log out
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Search settogglehamber={settogglehamber} auth={auth} />

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Form inline>
              <Nav className="mr-auto">
                <span style={{ display: "flex" }}>
                  <img src={flag} alt="pride flag" className="img-flag" />
                  <Link
                    className={
                      page != undefined && page.includes("/questions")
                        ? "active"
                        : null
                    }
                    to="/questions"
                  >
                    Questions
                  </Link>

                  <Link
                    to="/how-it-works"
                    className={
                      page != undefined && page.includes("/how-it-works")
                        ? "active"
                        : null
                    }
                  >
                    How it works
                  </Link>
                  {auth && (
                    <Dropdown>
                      <Dropdown.Toggle
                        className="user_logs"
                        id="dropdown-basic"
                      >
                        <img src={avatar} alt="logged-in user avatar" className="avatar-login" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link style={{ display: "block" }} to="account">
                          Account
                        </Link>
                        <Dropdown.Item>
                          <div onClick={() => removeTokenFromLocalStorage()}>
                            Log out
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  {/* {!auth && <Link to="/login">Login</Link>} */}
                </span>
              </Nav>
            </Form>
          </Navbar.Collapse>
          {/*  {!auth && (
            <div className="displayformonile">
              <button
                onClick={() => {
                  setchoosebox(true);
                  document.body.classList.remove("menuexpand");

                  dispatch(toggleMenu(false));
                }}
              >
                Sign up for free
              </button>
            </div>
          )} */}

          <span ref={showsocial}>
            <SocialBox />
          </span>

          <HamburgerMenu
            isOpen={togglehamber}
            menuClicked={togglemenu}
            width={28}
            height={15}
            strokeWidth={2}
            rotate={0}
            color="#404040"
            borderRadius={0}
            animationDuration={0.5}
            className="HamburgerMenu_icon"
          />
          {/* <Nav.Link href="" onClick={togglemenu} className="toogleimg">
          <img src={toggleimg} />
                </Nav.Link> */}
        </Navbar>
      </div>
      {choosebox && <Choose status={choosebox} setchoosebox={setchoosebox} />}
    </>
  );
}
