import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
export default function MenuItems() {
  return (
    <>
      <Col xs={5} sm={3}>
        <ul>
          <li>
            <Link to="/questions"> Questions</Link>
          </li>
          <li>
            <Link to="/how-it-works">How it works</Link>
          </li>
          <li>
            <Link to="/partners">Find Help</Link>
          </li>
          <li className="desktop">
            <Link to="/fertile-edge-magazine"> Fertile Edge Magazine</Link>
          </li>
          <li className="mobile">
            <Link to="/fertile-edge-magazine"> Fertile Edge </Link>
          </li>

          <li>
            <Link to="/about">About</Link>
          </li>
        </ul>
      </Col>
      <Col xs={7} sm={4}>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <ul className="bold-items">
            <li>
              <a href="/MedAnswers" target="_blank">
                MedAnswers
              </a>
            </li>
            <li>
              <Link to="/faqs"> FAQs</Link>
            </li>
            <li>
              <Link to="/contact"> Contact Us</Link>
            </li>
          </ul>

          <a target="_blank" href="https://app.medanswers.com/expert-sign-in">
            <p>Partner Log in</p>
          </a>
        </div>
      </Col>
    </>
  );
}