export const saveTokentoLocalStorage = (token) => {
  typeof window !== "undefined" && localStorage.setItem("auth-token", token);
  // typeof window !== "undefined" && window.location.reload(true);
};
export const saveUserInfo = (data) => {
  typeof window !== "undefined" && localStorage.setItem("user", data);
  // typeof window !== "undefined" && window.location.reload(true);
};

export const getTokenFromLocalStorage = () => {
  const token =
    typeof window !== "undefined" && localStorage.getItem("auth-token");

  if (token) return token;
  return "";
};

export const removeTokenFromLocalStorage = () => {
  console.log("IN REMOVE TOKEN");
  typeof window !== "undefined" && localStorage.removeItem("auth-token");
  typeof window !== "undefined" && window.location.reload(true);
  console.log("AFTER REMOVE TOKEN");
};
