import React, { useEffect } from "react";
import LoginModel from "./LoginModel/loginModel.js";
import "bootstrap/dist/css/bootstrap.min.css";

// component export
import Header from "./header/index";
import Footer from "./footer/index";
import Join from "./joinforFree/joinForFree";
import "./assets/css/layout.scss";
import Menu from "./menu/menu.js";
import { useStaticQuery, graphql } from "gatsby";
import { getTokenFromLocalStorage } from "../../api/utils/local-storage";
const Layout = ({ children, location = "", activepage = "" }) => {
  useEffect(() => {
    typeof window !== "undefined" &&
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src =
          "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
  }, []);

  const [auth, setAuth] = React.useState(false);
  useEffect(() => {
    const authToken = getTokenFromLocalStorage();
    if (authToken) setAuth(true);
  }, []);
  const data = useStaticQuery(graphql`
    {
      allCosmicjsFooterBanners {
        edges {
          node {
            metadata {
              button_text
              heading
            }
          }
        }
      }
    }
  `);

  return (
    <div className={activepage}>
      <div
        className={
          location.pathname != undefined && location.pathname.replace("/", "")
        }
      >
        <Header page={location.pathname} auth={auth} />

        {children}

        <div className="fixedheightsearch"></div>
        <div className="fixedheightsearchmobile"></div>
        {location.pathname != undefined &&
        location.pathname.includes("/fertile") ? null : (
          <Join
            heading={
              data.allCosmicjsFooterBanners.edges[0].node.metadata.heading
            }
            buttonText={
              data.allCosmicjsFooterBanners.edges[0].node.metadata.button_text
            }
          />
        )}

        <Footer />
        <LoginModel />

        <div className="menubar">
          <Menu />
        </div>
      </div>
    </div>
  );
};

export default Layout;
