import React, { useState, useEffect } from "react";
import "./assets/css/style.scss";
import { Link } from "gatsby";
import { Fade } from "react-awesome-reveal";
import Choose from "../../layout/chooseRole/index.js";
import { SocialBox } from "../menu/menu.js";
export default function JoinForFree({ heading, buttonText }) {
  const [choosebox, setchoosebox] = useState(false);
  return (
    <>
      <div className="joinForFree">
        <div className="content">
          <Fade direction="top" duration={500} delay={200}>
            <h1>{heading}</h1>
            {/* <button
              onClick={() => {
                setchoosebox(true);
              }}
            >
              {buttonText}
            </button> */}
            <SocialBox />
          </Fade>
        </div>
      </div>

      {choosebox && <Choose status={choosebox} setchoosebox={setchoosebox} />}
    </>
  );
}
