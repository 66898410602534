import React, { useState, useRef } from "react";
import angle from "../assets/images/angle.svg";
import cross from "../assets/images/cross.svg";
import searchmobile from "../assets/images/search.svg";
import { navigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../../../../state/app.js";
export default function Search({ settogglehamber, auth }) {
  const dispatch = useDispatch();
  const inputsearchcontainer = useRef();
  const inputcontainer = useRef();
  const [search_inp, setSearcs_inp] = useState();

  const SearchQuestions = (e) => {
    setSearcs_inp(e.target.value);
  };
  const viewSearch = (e) => {
    if (!!!search_inp) {
      return;
    }
    if (e.key === "Enter") {
      navigate(`/questions/search/?search=${search_inp}`);

      dispatch(toggleMenu(false));
      document.body.classList.remove("menuexpand");

      document
        .getElementsByClassName("fixedheightsearch")[0]
        .classList.remove("overlay");
      document
        .getElementsByClassName("fixedheightsearchmobile")[0]
        .classList.remove("overlay");

      settogglehamber(false);
    }
  };

  const searchquestionmobile = () => {
    if (!!!search_inp) {
      return;
    }

    /// document.body.classList.remove("menuexpand");
    document
      .getElementsByClassName("fixedheightsearch")[0]
      .classList.remove("overlay");
    document
      .getElementsByClassName("fixedheightsearchmobile")[0]
      .classList.remove("overlay");
    navigate(`/questions/search/?search=${search_inp}`);
    //settogglehamber(false);
  };

  const openSearchFormobile = () => {
    document
      .getElementsByClassName("mobilesearch custom")[0]
      .classList.toggle("show");

    document.body.classList.toggle("togglemenuformobile");

    document
      .getElementsByClassName("fixedheightsearchmobile")[0]
      .classList.remove("overlay");
  };

  const openSearchFordesktopmobile = () => {
    {
      document
        .getElementsByClassName("fixedheightsearch")[0]
        .classList.add("overlay");

      inputsearchcontainer.current.classList.add("activesearch");
    }
  };

  const closedSearchFordesktopmobile = () => {
    {
      document
        .getElementsByClassName("fixedheightsearch")[0]
        .classList.remove("overlay");

      inputsearchcontainer.current.classList.remove("activesearch");
    }
  };

  return (
    <>
      <img
        src={searchmobile}
        className="mobilesearch custom"
        onClick={openSearchFormobile}
        alt="open search"
      />

      <div className="autocomplete_container" ref={inputsearchcontainer}>
        <img
          src={angle}
          onClick={searchquestionmobile}
          className="mobile icons add"
          alt="search entered text"
        />

        <input
          className="searchinput_mian"
          type="text"
          placeholder="Search your fertility question"
          onFocus={openSearchFordesktopmobile}
          onBlur={closedSearchFordesktopmobile}
          onChange={SearchQuestions}
          onKeyDown={viewSearch}
          ref={inputcontainer}
          onClick={searchquestionmobile}
        />
        <img
          src={cross}
          className="mobile icons"
          onClick={openSearchFormobile}
          alt="close search"
        />
      </div>
    </>
  );
}
