import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "gatsby";
export default function Choose({ status = false, setchoosebox }) {
  return (
    <>
      <MyVerticallyCenteredModal
        show={status}
        onHide={() => setchoosebox(false)}
      />
    </>
  );
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="choosebutton">
          <Link to="/signup" className="Patient">
            I’m a Patient
          </Link>
          <Link to="/expert-signup" className="Expert">
            I’m an Expert
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
}
